<template>
  <div>
    <ConnectWallet />
    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1121285658795847692/What_is_virtualKOL.png');
        "
      >
        <div class="text-center">
          <span class="text-5xl font-bold sm:text-8xl text-white">
            What is VirtualKOL ?
          </span>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-center">
          <hr class="h-px my-4 border-blue-700 border" />

          <a
            class="text-xl font-medium xl:text-5xl hover:-translate-y-1 cursor-pointer text-shadow-white text-purple-900"
          >
            What is VirtualKOL ?
          </a>
          <hr class="h-px my-4 border-blue-700 border" />
        </div>

        <h3 class="text-xl font-medium xl:text-2xl text-purple-900">
          A virtual KOL (Key Opinion Leader) refers to an influencer or thought
          leader who primarily operates in the digital realm, often leveraging
          online platforms, social media, and virtual communities to engage with
          their audience. While traditional KOLs are individuals who possess
          expertise and influence in specific fields, virtual KOLs have embraced
          the digital landscape to build their online presence and establish
          credibility.
        </h3>

        <div class="flex justify-center">
          <ul class="list-disc text-lg xl:text-xl mt-5">
            <li class="mt-2">
              Virtual KOLs typically gain followers and build their reputation
              through various digital channels such as social media platforms
              (e.g., YouTube, Instagram, TikTok, Twitter), blogs, podcasts,
              webinars, and online forums. They produce content, share opinions,
              provide insights, and entertain their audience on specific topics
              or industries. Virtual KOLs can cover a wide range of subjects,
              including fashion, beauty, technology, gaming, travel, food, and
              many others.
            </li>
            <li class="mt-4">
              What distinguishes virtual KOLs is their ability to leverage
              technology to connect and engage with their audience. They often
              create engaging and interactive content, collaborate with brands
              for sponsored campaigns or endorsements, and maintain an active
              presence across different online platforms. Virtual KOLs can have
              a significant impact on consumer behavior and brand awareness, as
              they have cultivated a loyal following that values their opinions
              and recommendations.
            </li>
          </ul>
        </div>

        <h3 class="text-lg font-medium xl:text-lg mt-5">
          What distinguishes virtual KOLs is their ability to leverage
          technology to connect and engage with their audience. They often
          create engaging and interactive content, collaborate with brands for
          sponsored campaigns or endorsements, and maintain an active presence
          across different online platforms. Virtual KOLs can have a significant
          impact on consumer behavior and brand awareness, as they have
          cultivated a loyal following that values their opinions and
          recommendations.
        </h3>
      </div>

      <News />
      <Footer />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import News from "@/components/News.vue";
import Footer from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      news: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657420112053/AI_Replace_Human.png",
          title: "Will Artificial Intelligence (AI) replace humans ?",
          list: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657885683784/AI.png",
          title: "AI in agency industry",
          list: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658401579018/Comparison.png",
          title: "Comparison between virtual influencer and human influencer ?",
          list: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658795847692/What_is_virtualKOL.png",
          title: "What is VirtualKOL ?",
          list: "4",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    News,
    Footer,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
